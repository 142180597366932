.left .right {
  height: 100vh;
  width: 100%;
}

.left img,
.right img {
  width: 65%;
}

.left {
  background-color: #7da6da;
}

.right {
  background-color: #f0eddd;
}

.overlay {
  position: fixed;
  display: block;
  width: 50%;
  height: 100%;
  color: white;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlay .content {
  position: fixed;
  top: 50%;
  width: 50%;
  height: 50%;
  padding: 5em;
}

.right .overlay {
  left: 50%;
}
.left .overlay {
  left: 0%;
}

.up,
.down {
  position: absolute;
  /*Set our animation play state to paused initially */
  animation-play-state: running;
}

.up {
  animation: move-up 1s ease-out;
  animation-fill-mode: forwards;
}

.down {
  animation: move-down 1s ease-out;
  animation-fill-mode: forwards;
}

@keyframes move-up {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-45%);
  }
}
@keyframes move-down {
  0% {
    transform: translateY(-45%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .left,
  .right {
    height: 100vh;
  }
}

.bullet-point-group {
  display: flex;
  align-items: center;
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
}
/* unvisited link */
a:link {
  color: #f0eddd;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: blue;
}
.bullet-point-group .text {
  font-size: 1.5em;
  font-weight: 900;
  margin-left: 21px;
}

.bullet-point-group .icon {
  font-size: 2em;
}

.left .icon {
  color: #029244;
}
.right .icon {
  color: #d02117;
}
